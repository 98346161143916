import apiURLs from '../../constants/apiURLs';
import localStorage from '../../utils/localStorage';
import trimDataValues from '../../utils/trimDataValues';

class AuthEndpoint {
  constructor(axios, setBearer, removeBearer) {
    this.axios = axios;
    this.setBearer = setBearer;
    this.removeBearer = removeBearer;
  }

  login = async (email, password) => {
    try {
      const response = await this.axios({
        method: 'post',
        url: apiURLs.login,
        data: trimDataValues({ email, password }),
      });
      const { token, user } = response.data;
      const { first_name: firstName, last_name: secondName, id, roles } = user ?? {};
      let avatar;
      if (roles[0] === 'STUDENT') {
        avatar = user.profile.student.avatar;
      } else {
        avatar = user.profile.teacher.avatar;
      }
      const { access_token: accessToken } = token;
      localStorage.accessToken = accessToken;
      localStorage.role = roles;
      localStorage.userId = id;
      localStorage.userName = firstName;
      localStorage.secondName = secondName;
      localStorage.avatar = avatar;
      this.setBearer(accessToken);
      return response.data;
    } catch (error) {
      // to do. Везде придется писать эту проверку. Лучше воспользоваться вышеописанным CustomError
      throw error.response?.data?.error || error;
    }
  };

  signUp = async authData => {
    try {
      const response = await this.axios({
        method: 'post',
        url: apiURLs.signUp,
        data: trimDataValues({ ...authData, passwordConfirmation: authData.password }),
      });
      return response.data;
    } catch (error) {
      // to do. Везде придется писать эту проверку. Лучше воспользоваться вышеописанным CustomError
      throw error.response?.data?.error || error;
    }
  };

  confirm = async confirmationToken => {
    try {
      const response = await this.axios({
        method: 'post',
        url: apiURLs.confirm,
        data: { token: confirmationToken },
      });
      const { token, user } = response.data ?? {};
      const { access_token: accessToken } = token ?? {};
      const { first_name: firstName, last_name: secondName, id, roles } = user ?? {};
      // let avatar;
      // if (roles[0] === 'STUDENT') {
      //   avatar = user.profile.student.avatar;
      // } else {
      //   avatar = user.profile.teacher.avatar;
      // }
      localStorage.accessToken = accessToken;
      localStorage.role = roles;
      localStorage.userId = id;
      localStorage.userName = firstName;
      localStorage.secondName = secondName;
      // localStorage.avatar = avatar;
      this.setBearer(accessToken);
      return response.data;
    } catch (error) {
      throw error.response?.data?.error;
    }
  };

  logout = async () => {
    try {
      const response = await this.axios({
        method: 'post',
        url: apiURLs.logout,
      });
      localStorage.deleteAll();
      this.removeBearer();
      return response;
    } catch (error) {
      throw error.response?.data?.error || error;
    }
  };

  refreshToken = async () => {
    const response = await this.axios({
      method: 'post',
      url: apiURLs.refresh,
    });
    const { token, user } = response.data;
    localStorage.accessToken = token.access_token;
    localStorage.role = user.roles;
    this.setBearer(token.access_token);
    return response.data;
  };

  firstStepSignUpProfile = async data => {
    const filterData = { ...data };
    if (filterData.student.divergency === '') {
      delete filterData.student.divergency;
    }
    try {
      const response = await this.axios({
        method: 'POST',
        url: apiURLs.firstStepSignUpProfile,
        data: filterData,
        params: {
          _method: 'PUT',
        },
      });
      return response.data;
    } catch (error) {
      throw error.response?.data?.error || error;
    }
  };

  secondStepSignUpProfile = async data => {
    try {
      const response = await this.axios({
        method: 'POST',
        url: apiURLs.secondStepSignUpProfile,
        data,
        params: {
          _method: 'PUT',
        },
      });
      return response.data;
    } catch (error) {
      throw error.response?.data?.error || error;
    }
  };
}

export default AuthEndpoint;
