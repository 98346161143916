function pathWithSearch(self) {
  const { search } = window.location;
  return `${self.path}${search}`;
}

export default {
  home: {
    path: '/',
    navigationName: 'Главная',
    keyName: 'home',
  },
  training: {
    path: '/training',
    navigationName: 'Учебная программа',
    keyName: 'training',
  },
  resource: {
    path: id => `/training/${id}`,
    navigationName: 'Ресурс',
    keyName: 'resource',
  },
  authorization: {
    path: '/authorization',
    navigationName: 'Авторизация',
    keyName: 'authorization',
  },
  successSignUp: {
    path: '/success-sign-up',
    navigationName: 'Успешная регистрация',
    keyName: 'successSignUp',
  },
  successSignUpFirstStep: {
    path: '/success-sign-up/create-first-step',
    navigationName: 'Успешная регистрация',
    keyName: 'successSignUpFirstStep',
  },
  successSignUpSecondStep: {
    path: '/success-sign-up/second-step',
    navigationName: 'Успешная регистрация',
    keyName: 'successSignUpSecondStep',
  },
  successSignUpLastStep: {
    path: '/success-sign-up/last-step',
    navigationName: 'Успешная регистрация',
    keyName: 'successSignUpLastStep',
  },
  authConfirm: {
    path: '/registration-confirmation',
    keyName: 'authConfirmation',
  },
  lessonWaiting: {
    path: '/lesson/waiting',
    get pathWithSearch() {
      return pathWithSearch(this);
    },
    navigationName: 'Ожидание урока',
    keyName: 'lessonWaiting',
  },
  lessonBreak: {
    path: '/lesson/break',
    get pathWithSearch() {
      return pathWithSearch(this);
    },
    navigationName: 'Перемена',
    keyName: 'lessonBreak',
  },
  lessonEnd: {
    path: '/lesson/end',
    get pathWithSearch() {
      return pathWithSearch(this);
    },
    navigationName: 'Урок завершен',
    keyName: 'lessonEnd',
  },
  videoConference: {
    path: '/lesson/video-conference',
    get pathWithSearch() {
      return pathWithSearch(this);
    },
    navigationName: 'Видео конференция',
    keyName: 'videoConference',
  },
  profile: {
    path: '/profile',
    navigationName: 'Мой профиль',
    keyName: 'profile',
  },
  office: {
    path: '/office',
    navigationName: 'Мой кабинет',
    keyName: 'office',
  },
  changePassword: {
    path: '/change-password',
    navigationName: 'Смена пароля',
    keyName: 'changePassword',
  },
  recoveryPassword: {
    path: '/recovery-password',
    navigationName: 'Восстановление пароля',
    keyName: 'recoveryPassword',
  },
  changeEmail: {
    path: '/change-email-confirmation',
    navigationName: 'Смена почты',
    keyName: 'changeEmail',
  },
  editMentor: {
    path: id => `/edit-mentor/${id}`,
    navigationName: 'Мой профиль',
    keyName: 'editMentor',
  },
  moviePlayer: {
    path: (subjectId, id) => `/subject/${subjectId}/video/${id}`,
    navigationName: 'Видеопроигрыватель',
    keyName: 'moviePlayer',
  },
  kinescopePlayer: {
    path: (subjectId, id, kinescopeLink) => `/subject/${subjectId}/video/${id}/k/${kinescopeLink}`,
    navigationName: 'Видеопроигрыватель',
    keyName: 'kinescopePlayer',
  },
  myProgressMoviePlayer: {
    path: (subjectId, id) => `/subject/${subjectId}/my-progress-video/${id}`,
    navigationName: 'Видеопроигрыватель',
    keyName: 'myProgressMoviePlayer',
  },
  notificationMoviePlayer: {
    path: id => `/video/${id}`,
    navigationName: 'Видеопроигрыватель',
    keyName: 'notificationMoviePlayer',
  },
  notification: {
    path: `/notification`,
    navigationName: 'Уведомления',
    keyName: 'alert',
  },
};
