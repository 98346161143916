import React, { useMemo } from 'react';
import cn from 'classnames';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import Navigation from '../Navigation';
import navigation from '../../constants/navigation';
import { ReactComponent as LogoIconVariant1 } from '../../assets/img/logo_type_1.svg';
import onEnterKeyDecorator from '../../utils/onEnterKeyDecorator';
import localStorage from '../../utils/localStorage';
import JoinLesson from '../Modal/JoinLesson';
import { useStore } from '../../store';

export default function Header() {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const { role } = localStorage;

  const {
    modalWindowsStore: { openJoinLessonModal },
  } = useStore();

  const isAuthorizationPageActive = useRouteMatch(navigation.authorization.path);
  const isLessonWaitingPageActive = useRouteMatch(navigation.lessonWaiting.path);
  const isVideoConferencePageActive = useRouteMatch(navigation.videoConference.path);
  const isLessonBreakPageActive = useRouteMatch(navigation.lessonBreak.path);
  // const isTrainingPageActive = useRouteMatch(navigation.training.path);
  const isSuccessSignUp = useRouteMatch(navigation.successSignUp.path);
  const isSuccessSignUpFirstStep = useRouteMatch(navigation.successSignUpFirstStep.path);
  const isSuccessSignUpSecondStep = useRouteMatch(navigation.successSignUpSecondStep.path);
  const isSuccessSignUpLastStep = useRouteMatch(navigation.successSignUpLastStep.path);
  // const isProfilePageActive = useRouteMatch(navigation.profile.path);
  const isOfficePageActive = useRouteMatch(navigation.office.path);
  const isRecoveryPassword = useRouteMatch(navigation.recoveryPassword.path);

  const isLessonActivePage = useMemo(
    () => isLessonWaitingPageActive || isLessonBreakPageActive || isVideoConferencePageActive,
    [pathname],
  );

  const isShowNavigation = useMemo(
    () =>
      !(
        isAuthorizationPageActive ||
        isLessonActivePage ||
        isSuccessSignUp ||
        isRecoveryPassword ||
        isSuccessSignUpFirstStep ||
        isSuccessSignUpSecondStep ||
        isSuccessSignUpLastStep
      ),
    [pathname],
  );

  const isClickableLogo = useMemo(
    () => !(isSuccessSignUp || isAuthorizationPageActive || isRecoveryPassword),
    [pathname],
  );

  const onNavClick = path => {
    if (path === navigation.home.path && isLessonActivePage) {
      window.open(`${window.location.origin}${navigation.home.path}`, '_blank');
    } else {
      history.push(path);
    }
  };

  const onLogoClick = React.useCallback(() => {
    onNavClick(navigation.home.path);
  }, []);

  return (
    <header
      className={
        pathname === navigation.authorization.path ||
        pathname === navigation.successSignUp.path ||
        pathname === navigation.successSignUpFirstStep.path ||
        pathname === navigation.successSignUpSecondStep.path ||
        pathname === navigation.successSignUpLastStep.path ||
        pathname === navigation.recoveryPassword.path
          ? ''
          : 'header'
      }
    >
      <div className="main-header-container">
        <div className="header__content">
          <div className="header__logo-container">
            <LogoIconVariant1
              className="header__logo"
              onClick={(isClickableLogo || location.state === 'success') && onLogoClick}
              onKeyPress={
                (isClickableLogo || location.state === 'success') &&
                onEnterKeyDecorator(onLogoClick)
              }
              style={
                isClickableLogo || location.state === 'success'
                  ? { cursor: 'pointer' }
                  : { cursor: 'unset' }
              }
            />
            <p className="header__logo-text">
              Платформа для обучения
              <br />
              особых детей
            </p>
          </div>

          <nav className="header__nav">
            {isShowNavigation && (
              <>
                {/* <div
                className={cn('header_link', { 'header_is-active': isTrainingPageActive })}
                onClick={() => onNavClick(navigation.training.path)}
                onKeyPress={() => onEnterKeyDecorator(onNavClick(navigation.training.path))}
                tabIndex="0"
                role="button"
              >
                Учебная программа
              </div> */}

                <div
                  className="header_link"
                  onClick={() => openJoinLessonModal(true)}
                  onKeyPress={() => openJoinLessonModal(true)}
                  tabIndex="0"
                  role="button"
                >
                  На урок
                </div>
                {role !== 'ADMIN' && (
                  <div
                    className={cn('header_link', { 'header_is-active': isOfficePageActive })}
                    onClick={() => {
                      if (role === 'MENTOR') {
                        onNavClick('/office/time-sheet');
                      } else {
                        onNavClick('/office/time-sheet');
                      }
                    }}
                    onKeyPress={() => {
                      if (role === 'MENTOR') {
                        onEnterKeyDecorator(onNavClick('/office/time-sheet'));
                      } else {
                        onEnterKeyDecorator(onNavClick('/office/time-sheet'));
                      }
                    }}
                    tabIndex="0"
                    role="button"
                  >
                    Мой кабинет
                  </div>
                )}
                {(role === 'MENTOR' || role === 'ADMIN') && (
                  <a
                    className="header_link"
                    href={`${process.env.REACT_APP_ADMIN_URL}/admin`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Панель администрирования
                  </a>
                )}
              </>
            )}
          </nav>
          <Navigation />
          <JoinLesson />
          {/* {isShowNavigation && <Navigation />} */}
        </div>
      </div>
    </header>
  );
}
