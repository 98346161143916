import React, { useMemo, useEffect, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { toast } from 'react-toastify';
import navigation from '../../constants/navigation';
import { ReactComponent as LoginIcon } from '../../assets/img/login.svg';
import { ReactComponent as ProfileIcon } from '../../views/Profile/assets/adultIcon.svg';
import { ReactComponent as PasswordIcon } from '../../assets/img/passwordIcon.svg';
import { ReactComponent as RingIcon } from '../../assets/img/ringIcon.svg';
import defaultIcon from '../../views/Profile/assets/defaultPerson.svg';
import { authApi, profileApi } from '../../api/apiEndpoints';
import { useStore } from '../../store';
import { lesson } from '../../Lesson/Lesson';
import localStorage from '../../utils/localStorage';
import getTimeZone from '../../utils/getTimeZone';

function Navigation() {
  const {
    userStore: { isAuthorized, setIsAuthorized },
    preloaderStore: { openMainPreloader },
  } = useStore();
  const location = useLocation();
  const history = useHistory();
  const [profile, setProfile] = useState(null);
  const [notification, setNotification] = useState(false);
  const { pathname } = location;
  const { role } = localStorage;

  const roleInSystem = { ADMIN: 'Администратор', MENTOR: 'Педагог', STUDENT: 'Ученик' };

  const isLessonWaitingPageActive = useRouteMatch(navigation.lessonWaiting.path);
  const isVideoConferencePageActive = useRouteMatch(navigation.videoConference.path);
  const isLessonBreakPageActive = useRouteMatch(navigation.lessonBreak.path);
  const isAuthorizationPageActive = useRouteMatch(navigation.authorization.path);
  const isSuccessSignUp = useRouteMatch(navigation.successSignUp.path);
  const isRecoveryPassword = useRouteMatch(navigation.recoveryPassword.path);

  const isLessonActivePage = useMemo(
    () => isLessonWaitingPageActive || isLessonBreakPageActive || isVideoConferencePageActive,
    [pathname],
  );

  const isNonAuthUser = useMemo(
    () => isAuthorizationPageActive || isSuccessSignUp || isRecoveryPassword,
    [pathname],
  );

  const onNavClick = path => {
    if (
      (path === navigation.profile.path ||
        path === navigation.changePassword.path ||
        path === navigation.notification.path) &&
      isLessonActivePage
    ) {
      window.open(`${window.location.origin}${path}`, '_blank');
    } else {
      history.push(path);
    }
  };

  const onLoginClick = () => {
    if (isAuthorized) {
      openMainPreloader(true);
      if (lesson) {
        lesson.exitFromLesson(true);
      }
      authApi
        .logout()
        .then(() => {
          setIsAuthorized(false);
          history.push(navigation.authorization.path);
        })
        .catch(e => {
          toast.error(e.message);
        })
        .finally(() => openMainPreloader(false));
    }
    history.push(navigation.authorization.path);
  };

  useEffect(() => {
    if (
      pathname !== navigation.authorization.path &&
      pathname !== navigation.successSignUp.path &&
      pathname !== navigation.recoveryPassword.path
    ) {
      profileApi
        .getNotification(1, getTimeZone())
        .then(res => {
          setProfile({
            first_name: res.data?.user.user.first_name,
            last_name: res.data?.user.user.last_name,
            avatar:
              res.data?.user?.profile?.mentor?.avatar || res.data?.user?.profile?.student?.avatar,
          });
          setNotification(res.data.unreaded_count);
        })
        .catch(() => setProfile(null));
    }
  }, [pathname]);

  return (
    <nav className="header__navigation">
      {isAuthorized && profile && !isNonAuthUser && (
        <>
          <button
            type="button"
            className="alert-button"
            onClick={() => onNavClick(navigation.notification.path)}
          >
            {notification > 0 && <span className="alert-button-active" />}
            <RingIcon />
          </button>
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              <div className="header__navigation_user-wrapper">
                <img
                  src={profile.avatar || defaultIcon}
                  alt="avatar"
                  className="header__navigation_user-icon"
                />
                <div className="header__navigation_user-info">
                  <p className="header__navigation_user-name">
                    {profile.first_name || 'Неизвестный'}&nbsp;
                    {profile.last_name[0] || 'П'}.
                  </p>
                  <p className="header__navigation_user-role">{roleInSystem[role]}</p>
                </div>
              </div>
            </DropdownToggle>
            <DropdownMenu right>
              {role !== 'ADMIN' && (
                <DropdownItem
                  onClick={() => onNavClick(navigation.profile.path)}
                  className="header__navigation_menu-item"
                >
                  <ProfileIcon className="header__navigation_menu-icon" />
                  Мой профиль
                </DropdownItem>
              )}
              <DropdownItem
                onClick={() => onNavClick(navigation.changePassword.path)}
                className="header__navigation_menu-item"
              >
                <PasswordIcon className="header__navigation_menu-icon" />
                Изменить пароль
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={onLoginClick} className="header__navigation_menu-item">
                <LoginIcon className="header__navigation_menu-logout-icon" />
                Выйти
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </>
      )}
    </nav>
  );
}

export default Navigation;
