export default {
  // auth
  login: '/login',
  signUp: '/students/sign-up',
  confirm: '/users/confirm',
  logout: '/logout',
  refresh: '/refresh',
  profile: (role, id) => (id ? `/${role}/${id}` : `/${role}`),
  educationClasses: '/student/education-classes',
  educationFormats: '/student/education-formats',
  adultRoles: '/student/adult-roles',
  searchCity: city => `/student/search_city?city_name=${city}`,
  chatAttachment: '/chat/attachment',
  fileDelete: id => `/chat/attachment/${id}`,
  getFile: (hash, code) =>
    `${process.env.REACT_APP_BASE_API_URL}/chat/attachment?lessonHash=${hash}&code=${code}`,
  training: '/folders',
  resource: id => `/folders/${id}/resources`,
  downloadFile: id => `/folders/${id}/resources/download`,
  deleteAccount: '/users/delete-account',
  changePassword: '/users/change-password',
  recoveryPassword: '/users/recovery-password',
  restorePassword: '/users/restore-password',
  restoreAccount: '/users/restore-account',
  changeEmailConfirmation: '/users/change-email-confirm',
  me: '/me',
  getEducationFormTemplates: '/education-format-templates',
  getProducts: '/products',
  getListOfProducts: id => `/products/education-format-no-countable-elements/${id}`,
  getSubjects: id => `/students/${id}/subjects`,
  makeOrder: '/orders',
  getOrdersList: '/student/orders',
  getMaterials: (userId, subjectId) =>
    `/students/${userId}/subjects/${subjectId}/no-countable-element-items`,
  getNestedMaterials: (userId, subjectId, folderId) =>
    `/students/${userId}/subjects/${subjectId}/no-countable-element-items/${folderId}`,
  getMyProgress: (userId, subjectId) =>
    `/students/${userId}/subjects/${subjectId}/student-resources-and-folders`,
  getResourcesFile: id => `/student-resources/files/${id}`,
  getMentors: '/mentors',
  createStudentResource: '/student-resources',
  getVideoInMaterials: (userId, subjectId, recourceId) =>
    `/students/${userId}/subjects/${subjectId}/no-countable-element-items/videos/${recourceId}`,
  getCurrentSubject: id => `/subjects/${id}`,
  getAdditionalList: (userId, materialId) =>
    `/students/${userId}/no-countable-elements/${materialId}/items`,
  getAdditionalNestedList: (userId, materialId, folderId) =>
    `/students/${userId}/no-countable-elements/${materialId}/items/${folderId}`,
  downloadMaterial: id => `/resources/${id}/download`,
  getSubjectsForSelect: '/subjects',
  deleteStudentResource: id => `/student-resources/${id}`,
  getDownloadLinkMyProgress: id => `/student-resources/${id}/download`,
  getStudentsList: '/students',
  getStudentsFilters: '/student/filters',
  downloadStudentList: '/students/download',
  createFolder: '/student-folders',
  deleteFolder: id => `/student-folders/${id}`,
  getMyNestedProgress: (userId, subjectId, folderId) =>
    `/students/${userId}/subjects/${subjectId}/student-resources-and-folders/${folderId}`,
  getTeacherEducationEvents: '/education-events/teacher',
  getCurrentTeacherEducationEvents: id => `/education-events/teachers/${id}`,
  getStudentEducationEvents: '/education-events/student',
  getCurrentStudentEducationEvents: id => `/education-events/students/${id}`,
  getEducationEventStatuses: '/education-events/statuses',
  getNotification: '/notifications',
  readNotification: id => `/notifications/${id}/read`,
  getEventList: '/education-event-types',
  getEventTimeList: '/education-events/times',
  createEducationEvent: '/education-events',
  deleteEvent: id => `/education-events/${id}`,
  changeEventStatus: id => `/education-events/${id}/status`,
  editEducationEvent: id => `/education-events/${id}`,
  downloadTeacherEvents: '/education-events/teacher/download',
  firstStepSignUpProfile: '/student/profile/first-step',
  secondStepSignUpProfile: '/student/profile/second-step',
  divergenciesList: '/students/divergencies',
  downloadStudentEvents: '/education-events/student/download',
  downloadCurrentStudentEvents: id => `/education-events/students/${id}/download`,
  downloadCurrentTeacherEvents: id => `/education-events/teachers/${id}/download`,
  checkHomeWork: id => `/student-resources/${id}/check`,
  unCheckHomeWork: id => `/student-resources/${id}/uncheck`,
};
