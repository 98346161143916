import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Router, Switch, Route } from 'react-router';

import Container from './components/Container/Container';
import Header from './components/Header/Header';
import navigation from './constants/navigation';
import history from './utils/history/history';
import ErrorHandler from './components/ErrorHandler';
import Error from './views/Error/Error';
import MobilePlug from './views/MobilePlug/MobilePlug';
import useDisplayForbiddenBrowserNotification from './utils/reactHooks/useDisplayForbiddenBrowserNotification';
import { useStore } from './store';

import isMobileOS from './constants/isMobileOS';
import Footer from './components/Footer/Footer';

const Home = React.lazy(() => import('./views/Home/Home'));
const Authorization = React.lazy(() => import('./views/Authorization/Authorization'));
const LessonWaiting = React.lazy(() => import('./views/LessonWaiting/LessonWaiting'));
const LessonBreak = React.lazy(() => import('./views/LessonBreak/LessonBreak'));
const LessonEnd = React.lazy(() => import('./views/LessonEnd/LessonEnd'));
const VideoConference = React.lazy(() => import('./views/VideoConference/VideoConference'));
const SuccessSignUp = React.lazy(() => import('./views/SuccessSignUp/SuccessSignUp'));
const successSignUpFirstStep = React.lazy(() =>
  import('./views/SuccessSignUp/CreateProfileStages/CreateProfileFirstStage'),
);
const successSignUpSecondStep = React.lazy(() =>
  import('./views/SuccessSignUp/CreateProfileStages/CreateProfileSecondStage'),
);
const successSignUpLastStep = React.lazy(() =>
  import('./views/SuccessSignUp/CreateProfileStages/CreateProfileLastStage'),
);
// const Training = React.lazy(() => import('./views/Training/Training'));
const Resource = React.lazy(() => import('./views/Training/Resources/Resource'));
const Profile = React.lazy(() => import('./views/Profile/Profile'));
const ChangePasswordPage = React.lazy(() => import('./views/ChangePassword/ChangePassword'));
const RecoveryPassword = React.lazy(() => import('./components/Recovery/Recovery'));
const ChangeEmailConfirm = React.lazy(() =>
  import('./views/ChangeEmailConfirm/ChangeEmailConfirm'),
);
const MoviePlayer = React.lazy(() => import('./components/MoviePlayer/MoviePlayer'));
const KinescopePlayer = React.lazy(() => import('./components/Materials/KinescopePlayer'));
const MyOffice = React.lazy(() => import('./views/MyOffice/MyOffice'));
const Alert = React.lazy(() => import('./components/Alert/Alert'));

function App() {
  const {
    globalPropsStore: { globalError },
  } = useStore();

  useDisplayForbiddenBrowserNotification(history);

  useEffect(() => {
    if (globalError) {
      const { pathname, search } = history.location;
      const { code, message } = globalError;
      history.replace({ pathname, search }, { errorStatusCode: code, errorMessage: message });
    }
  }, [globalError]);

  if (isMobileOS && window.screen.width < 768) {
    return <MobilePlug />;
  }

  return (
    <Router history={history}>
      <Header />
      <Container classname="main-container">
        <Container classname="content-container">
          <ErrorHandler>
            {!globalError && (
              <Switch>
                <Route path={navigation.home.path} exact component={Home} />
                <Route path={navigation.authorization.path} component={Authorization} />
                <Route
                  path={navigation.successSignUpFirstStep.path}
                  component={successSignUpFirstStep}
                />
                <Route
                  path={navigation.successSignUpSecondStep.path}
                  component={successSignUpSecondStep}
                />
                <Route
                  path={navigation.successSignUpLastStep.path}
                  component={successSignUpLastStep}
                />
                <Route path={navigation.successSignUp.path} component={SuccessSignUp} />
                <Route path={navigation.lessonWaiting.path} component={LessonWaiting} />
                <Route path={navigation.lessonBreak.path} component={LessonBreak} />
                <Route path={navigation.lessonEnd.path} component={LessonEnd} />
                <Route path={navigation.resource.path(':id')} component={Resource} />
                <Route path={navigation.profile.path} component={Profile} />
                <Route path={navigation.editMentor.path(':id')} component={Profile} />
                <Route path={navigation.videoConference.path} component={VideoConference} />
                <Route path={navigation.changePassword.path} component={ChangePasswordPage} />
                <Route path={navigation.recoveryPassword.path} component={RecoveryPassword} />
                <Route path={navigation.changeEmail.path} component={ChangeEmailConfirm} />
                <Route path={navigation.office.path} component={MyOffice} />
                <Route
                  path={navigation.kinescopePlayer.path(':subjectId', ':id', ':kinescopeLink')}
                  component={KinescopePlayer}
                />
                <Route
                  path={navigation.notificationMoviePlayer.path(':id')}
                  component={MoviePlayer}
                />
                <Route
                  path={navigation.myProgressMoviePlayer.path(':subjectId', ':id')}
                  component={MoviePlayer}
                />
                <Route
                  path={navigation.moviePlayer.path(':subjectId', ':id')}
                  component={MoviePlayer}
                />
                <Route path={navigation.notification.path} component={Alert} />
                <Route path="*" component={Error} />
              </Switch>
            )}
          </ErrorHandler>
        </Container>
      </Container>
      <Footer />
    </Router>
  );
}

export default observer(App);
